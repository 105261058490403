import * as React from "react";
import constants from "constants/index";
import Styled from "styled-components/macro";
import Media from "@uizard-io/styled-media-query";
// @ts-ignore
import ComparisonHeadline from "../../../../assets/comparison-headline-image-a.svg";
import Description from "../../../texts/Description";
import Button from "../../../buttons/Button";
import Headline from "../../../texts/Headline";

const Container = Styled.div`
    position: absolute;
    z-index: 10;
    left: 50%;
    transform: translateX(-50%);
    width: 68rem;
    top: 16%;
    height: calc(100% - 16%);
    padding: 5rem 3.5rem;
    text-align: center;
    
    ${Media.lessThan("900px")`
        width: 33.5rem;
        padding: 5rem 2rem;
        
        svg {
          height: 7.8rem;
        }
    `}
`;

const HeadlineExt = Styled(Headline)`
  ${Media.greaterThan("900px")`
    font-size: 50px;
    margin-top: -45px;
  `}

  font-size: 20px;
  margin-top: -65px;
  color: #FFFFFF;
`;

const ExtendedDescriptionExt = Styled(Description)`
  ${Media.greaterThan("900px")`
    max-width: 900px;
    font-weight: 900;
    margin-bottom: 100px !important;
    font-size: 26px;
  `}

  color: #FFFFFF;
  margin: auto;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 10px;
`;

const ShoutoutSignupWrapper = Styled.div`
    max-width: 43rem;
    margin: 0 auto;
    margin-top: 3rem;
    
    ${Media.lessThan("900px")`
        margin-top: 1.5rem;
        max-width: 100%;
    `}
`;

const NavButtonLink = Styled.a`
  
`;

type ComparisonLandingProps = {
  title: string;
  tagline: string;
};

export default ({ title, tagline }: ComparisonLandingProps) => (
  <Container>
    <HeadlineExt as="h1">{title}</HeadlineExt>
    <ExtendedDescriptionExt>{tagline}</ExtendedDescriptionExt>
    <ShoutoutSignupWrapper>
      <NavButtonLink href={`https://${constants.APP_DOMAIN}/auth/sign-up`}>
        <Button solid>Sign up for free</Button>
      </NavButtonLink>
    </ShoutoutSignupWrapper>
  </Container>
);
