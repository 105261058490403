import * as React from "react";
import Styled from "styled-components/macro";
import Media from "@uizard-io/styled-media-query";
import Image, { GatsbyImageProps } from "gatsby-image";
import Content from "../../../layout/Content/Content";
import ComparisonFigmaLandingContent from "../ComparisonFigmaLandingContent";
import MediaQuery from "../../../layout/MediaQuery";

const ImageA = Styled(Image)`
    width: calc(100%);
    box-shadow: 0px 2px 20px rgba(3, 3, 3, 0.1);
    height: 730px;
`;

const ImageB = Styled(Image)`
    width: calc(100%);
    box-shadow: 0px 2px 20px rgba(3, 3, 3, 0.1);
    height: 365px;
`;

const Container = Styled.div`
    position: relative;
    
    svg {
        max-width: 100%;

    }
`;

const ExtendedContent = Styled(Content)`
    width: 100%;
    margin-top: 5rem;
    max-width: 100%;
    background-color: #6387F6;
`;

type ComparisonLandingProps = {
  title: string;
  tagline: string;
  imageA: GatsbyImageProps;
  imageB: GatsbyImageProps;
};

export default ({ imageA, imageB, tagline, title }: ComparisonLandingProps) => (
  <ExtendedContent>
    <Container>
      <ComparisonFigmaLandingContent title={title} tagline={tagline} />

      <MediaQuery lessThan="900px">
        <ImageB
          fluid={"fluid" in imageB ? imageB.fluid : null}
          alt="Screenshot of the Uizard's design tool"
        />
      </MediaQuery>

      <MediaQuery greaterThan="900px">
        <ImageA
          fluid={"fluid" in imageA ? imageA.fluid : null}
          alt="Screenshot of the Uizard's design tool"
        />
      </MediaQuery>
    </Container>
  </ExtendedContent>
);
