import * as React from "react";
import Styled from "styled-components/macro";
import Media from "@uizard-io/styled-media-query";
import Content from "../../../layout/Content/Content";
import BasicCard, { BasicCardContainer } from "../../../cards/BasicCard";
import Headline from "../../../texts/Headline";
import Description from "../../../texts/Description";
import BasicIcon from "../../../../assets/icon-basic-card.svg";

const Icon = Styled.div`
    background-color: #E7E7E7; 
    border-radius: 1.5rem;
    width: 5rem;
    height: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
`;

const Columns = Styled.div`
  display: flex; 
  justify-content: space-between;
  
  ${Media.lessThan("600px")`
    flex-direction: column;
  `} 
  
  ${BasicCardContainer} {
    margin-bottom: 3rem;
    padding: 9rem;
    background-color: ${(props) => props.theme.colors.gray.lighter}; 
    
    ${Media.lessThan("1024px")`
        padding: 3rem;
    `} 
  }
`;

const Column = Styled.div`
  width: calc(50% - 1.45rem);
  
  ${Media.lessThan("600px")`
    width: 100%;
  `} 
`;

const Row = Styled.div`
  width: 100%;
  
  ${Media.lessThan("600px")`
    width: 100%;
  `} 
`;

export default () => (
  <Content>
    <Columns>
      <BasicCardContainer>
        <Icon>
          <BasicIcon />
        </Icon>
        <Headline as="h3">Why Uizard?</Headline>
        <Description>
          Uizard was built from the ground up for non-technical users and
          non-designers alike as an easy tool to design digital products.
          <br />
          <br />
          With Uizard, you can design mobile apps, websites, and software
          interfaces with ease. No formal training required!
          <br />
          <br />
          We are passionate about design; we are passionate about customer
          experience. The Uizard team prides itself on providing a magical
          design solution to designers the world over.
          <br />
          <br />
          Oh, and by the way… Uizard is not an Adobe product.
        </Description>
      </BasicCardContainer>
    </Columns>
  </Content>
);
