import * as React from "react";
import Styled from "styled-components/macro";
import Media from "@uizard-io/styled-media-query";
import Content from "../../../layout/Content/Content";
import BasicCard, { BasicCardContainer } from "../../../cards/BasicCard";

const Columns = Styled.div`
  display: flex; 
  justify-content: space-between;
  
  ${Media.lessThan("600px")`
    flex-direction: column;
  `} 
  
  ${BasicCardContainer} {
    margin-bottom: 3rem;
    padding: 9rem;
    
    ${Media.lessThan("1024px")`
        padding: 3rem;
    `} 
  }
`;

const Column = Styled.div`
  width: calc(50% - 1.45rem);
  
  ${Media.lessThan("600px")`
    width: 100%;
  `}
`;

export default () => (
  <Content>
    <Columns>
      <Column>
        <BasicCard
          icon="support"
          headline="For distributed teams"
          description="Just like Figma, with Uizard you can invite anyone to collaborate in real-time regardless of whether they are sitting next to you in the office or working from home on the other side of the planet. Use our smart sharing features to share your project with the click of a button."
        />

        <BasicCard
          icon="component"
          headline="Dynamic product templates"
          description="No more blank screens! Uizard templates always reflect your project theme and increase your flexibility while decreasing your work time. Designing digital products has never been so easy."
        />
      </Column>

      <Column>
        <BasicCard
          icon="ai"
          dark
          headline="AI design assistant"
          description="Want a sneak peek into the future of design? AI is here and it's here to stay. Uizard's AI features turn even the most basic design tasks into streamlined experiences. Convert sketches to wireframes, use AI to generate text ideas, you can even import themes from other websites!"
        />

        <BasicCard
          icon="browser"
          headline="In browser mastery"
          description="Uizard works in your web browser: no software or plugin install required! It works across all platforms and all operating systems: Windows, Linux, Chrome, and Mac. Just open a tab and start creating!"
        />
      </Column>
    </Columns>
  </Content>
);
