import * as React from "react";
import Meta from "../../components/meta";
import Styled from "styled-components/macro";
import Media from "@uizard-io/styled-media-query";
import { graphql } from "gatsby";
import Content from "../../components/layout/Content/Content";
import Layout from "../../components/layout/Layout";
import ComparisonFigmaAlternative from "../../components/pages/uizard-vs-design-tools/ComparisonFigmaAlternative";
import ComparisonFeaturesFigma from "../../components/pages/uizard-vs-design-tools/ComparisonFeaturesFigma";
import ComparisonScreens from "../../components/pages/uizard-vs-design-tools/ComparisonScreens";
import ComparisonFirstLargeBlock from "../../components/pages/uizard-vs-design-tools/ComparisonFirstLargeBlock";
import ComparisonSecondLargeBlock from "../../components/pages/uizard-vs-design-tools/ComparisonSecondLargeBlock";
import BlogHighlightNarrowSpacing from "../../components/pages/blog/BlogHighlightNarrowSpacing";
import Shoutout from "../../components/pages/signup/Shoutout";

import blogThumbnail2 from "../../components/pages/blog/BlogThumbnails/Design-101-Design-basics-for-non-designers.png";
import blogThumbnail1 from "../../components/pages/blog/BlogThumbnails/Uizard-vs-Figma.png";
import blogThumbnail3 from "../../components/pages/blog/BlogThumbnails/Figjam-to-Uizard.png";

const Container = Styled.div`
  margin: 10rem 0 10rem 0;

  ${Media.greaterThan("medium")`
    margin: 12rem 0 10rem 0;
  `}
`;

const Comparison = ({ data }) => (
  <Layout>
    <Meta
      title="Uizard Vs Figma | Free Figma Alternatives | Uizard"
      description="Looking for an alternative to Figma? Uizard is a free alternative to Figma designed with speed & customization in mind. Bring your ideas to life today."
      url="/figma-alternative/"
    />

    <ComparisonFigmaAlternative
      title="Uizard VS Figma"
      tagline="Uizard is an easy-to-use alternative to Figma for designing apps, websites, and user interfaces."
      imageA={data.comparisonLandingImageA.childImageSharp}
      imageB={data.comparisonLandingImageB.childImageSharp}
    />

    <Container>
      <ComparisonFirstLargeBlock />

      <BlogHighlightNarrowSpacing
        title="Uizard is an easy-to-use Figma alternative"
        blogs={[
          {
            image: blogThumbnail1,
            link: "https://uizard.io/blog/uizard-vs-figma-the-similarities-and-the-key-differences/",
            alt: "Thumbnail for blog titled Uizard VS Figma",
          },
          {
            image: blogThumbnail2,
            link: "https://uizard.io/blog/design-101-design-basics-for-non-designers/",
            alt: "Thumbnail for blog titled Design 101: Design basics for non-designers",
          },
          {
            image: blogThumbnail3,
            link: "https://uizard.io/blog/how-to-get-from-figjam-to-uizard/",
            alt: "Thumbnail for blog titled How to go from FigJam to Uizard",
          },
        ]}
      />

      <ComparisonSecondLargeBlock />

      <ComparisonScreens
        imageA={data.comparisonScreensImageA.childImageSharp}
        imageB={data.comparisonScreensImageB.childImageSharp}
      />

      <ComparisonFeaturesFigma />

      <Content>
        <Shoutout
          headline="Uizard, the easy-to-use Figma alternative"
          description="Liberate yourself from complicated design tools"
          cta="Sign up for free"
          imageA={data.shoutoutImageA.childImageSharp}
        />
      </Content>
    </Container>
  </Layout>
);

export default Comparison;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    comparisonLandingImageA: file(
      relativePath: { eq: "comparison/ligma-big.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2200, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    comparisonLandingImageB: file(
      relativePath: { eq: "comparison/ligma-small.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 750, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    comparisonScreensImageA: file(
      relativePath: { eq: "comparison/screens-image-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1512, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    comparisonScreensImageB: file(
      relativePath: { eq: "comparison/screens-image-b.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1008, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    shoutoutImageA: file(
      relativePath: { eq: "shoutout/shoutout-image-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1232, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
