import * as React from "react";
import Styled from "styled-components/macro";
import Media from "@uizard-io/styled-media-query";
import Content from "../../../layout/Content/Content";
import BasicCard, { BasicCardContainer } from "../../../cards/BasicCard";
import Headline from "../../../texts/Headline";
import Description from "../../../texts/Description";
import BasicIcon from "../../../../assets/icon-basic-card.svg";

const Icon = Styled.div`
    background-color: #E7E7E7; 
    border-radius: 1.5rem;
    width: 5rem;
    height: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
`;

const Columns = Styled.div`
  display: flex; 
  justify-content: space-between;
  
  ${Media.lessThan("600px")`
    flex-direction: column;
  `} 
  
  ${BasicCardContainer} {
    margin-bottom: 3rem;
    padding: 9rem;
    background-color: ${(props) => props.theme.colors.gray.lighter}; 
    
    ${Media.lessThan("1024px")`
        padding: 3rem;
    `} 
  }
`;

const Column = Styled.div`
  width: calc(50% - 1.45rem);
  
  ${Media.lessThan("600px")`
    width: 100%;
  `} 
`;

const Row = Styled.div`
  width: 100%;
  
  ${Media.lessThan("600px")`
    width: 100%;
  `} 
`;

const Link = Styled.a`
  font-weight: 900;
  text-decoration: underline;
`;

export default () => (
  <Content>
    <Columns>
      <BasicCardContainer>
        <Headline as="h2">Built by designers for non-designers</Headline>
        <Description>
          Uizard is a free alternative to Figma designed with speed,
          ease-of-use, and customization in mind.
          <br />
          <br />
          Powered by <Link href="/design-assistant/">AI</Link>, Uizard empowers
          you to bring your ideas to life rapidly and collaboratively like never
          before.
        </Description>
      </BasicCardContainer>
    </Columns>
  </Content>
);
